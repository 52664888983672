<template>
    <div class="Overview" :class="{ 'is-loading': isFetchingData }">
        <div v-if="showLoader" :class="['Loader', { LoaderHidden: !isFetchingData }]">
            <div class="LoaderContent">
                <div class="LoaderSpinner"></div>
                <p>Laden der Daten...</p>
            </div>
        </div>

        <div style="font-size: 1rem; margin-bottom: 30px;">
            <Datepicker
                monthPicker
                @onChange="handleDateChange"
                :startDate="startDate"
                :end-date="endDate"
                :has-quick-actions="true"
            />
        </div>
        <ChartV2
            v-if="aggregatedMonthlyData.length"
            halfHeight
            color="green"
            type="bar"
            title=" "
            :values="aggregatedMonthlyData"
            @onLeave="activeRow = null"
        >
            <RevenueSummary
                :currentRevenue="yearlyIncome"
                :comparisonRevenue="prevYearIncome"
                :currentStartDate="startDate"
                :currentEndDate="endDate"
                :comparisonStartDate="subMonths(startDate, 12)"
                :comparisonEndDate="subMonths(endDate, 12)"
            />
        </ChartV2>
        <ChartV2
            v-if="data.length && !aggregatedMonthlyData.length"
            halfHeight
            color="green"
            type="bar"
            :values="data"
            title=" "
            @onLeave="activeRow = null"
        >
            <RevenueSummary
                :currentRevenue="monthlyIncome"
                :comparisonRevenue="lastMonthIncome"
                :currentStartDate="startDate"
                :currentEndDate="endDate"
                :comparisonStartDate="subMonths(startDate, 1)"
                :comparisonEndDate="subMonths(endDate, 1)"
            />
        </ChartV2>
        <div class="OverviewCards">
            <Card variant="info" v-if="widgets.find(widget => widget.type === 'targetIncome')">
                <IconCog
                    style="color:var(--color-text-blue);position: absolute;top: 10px;right: 10px;z-index: 1; cursor: pointer;"
                    @click.native="handleOnSettingsClick"
                />
                <Progress
                    size="small"
                    variant="vertical"
                    :showValues="true"
                    :target="targetIncome * 100"
                    :current="monthlyIncome"
                >
                    <h2>
                        {{ priceToEuroString(monthlyIncome) }}
                    </h2>
                </Progress>
            </Card>

            <Card variant="info">
                <p>Bester Fahrer: {{ highestIncomeDriver?.name }}</p>
                <h3>
                    {{ priceToEuroString(highestIncomeDriver?.shiftSums?.income) }}
                </h3>
            </Card>

            <Card variant="info">
                <p>Bestes Fahrzeug: {{ highestIncomeCar?.licenseNumber }}</p>
                <h3>
                    {{ priceToEuroString(highestIncomeCar?.shiftSums?.income) }}
                </h3>
            </Card>
            <Card variant="warning" v-if="dueReminders.length">
                <p>Fällige Termine</p>
                <h3>{{ dueReminders?.length }} Einträge</h3>
            </Card>
            <Card variant="warning" v-if="incorrectLogin.length">
                <p>Falschanmeldungen</p>
                <h3>{{ incorrectLogin?.length }} Einträge</h3>
                <Button
                    v-if="incorrectLogin?.length > 0"
                    size="small"
                    :marginTop="2"
                    style="align-self: flex-end"
                    @click.native="handleIncorrectEditClick"
                    >Bearbeiten
                </Button>
            </Card>
        </div>

        <div class="Wrap" v-if="!hasNoData">
            <Card class="col">
                <RowWrap width="auto">
                    <template #header>
                        <Row
                            v-if="sortedDrivers.length > 0"
                            variant="minimal"
                            :parentHover="false"
                            :items="{ row: driverHeader }"
                            :key="-1"
                            :index="-1"
                            @onClickColumn="handleDriverHeaderClick"
                            class="Row-Header"
                        />
                    </template>
                    <Row
                        v-for="(driver, i) in sortedDrivers"
                        variant="minimal"
                        :items="driver"
                        :key="i"
                        :hasBadge="driver.isSelected"
                        :hasClick="true"
                        :index="i"
                        :isActive="driver.isSelected"
                        @onClick="handleDriverClick"
                        @onHover="args => $emit('onRowHover', args)"
                        @onLeave="args => $emit('onRowLeave', args)"
                    />
                </RowWrap>
                <p v-if="!sortedDrivers.length">Noch keine Daten vorhanden.</p>
            </Card>
            <Card class="col" v-if="hasReminderFeature && groupedReminders.length">
                <div
                    v-for="(reminders, i) in groupedReminders"
                    :key="i"
                    style="margin-bottom: 20px;"
                >
                    <RowWrap width="auto">
                        <template #header>
                            <Row
                                v-if="reminders.items.length > 0"
                                variant="minimal"
                                class="Row-Header"
                                :parentHover="false"
                                :items="{
                                    row: reminderHeader.map((h, i) => ({
                                        ...h,
                                        name: i === 0 ? h.name + ' ' + reminders.entity : h.name,
                                    })),
                                }"
                                :key="-1"
                                :index="-1"
                                @onClickColumn="() => {}"
                            />
                        </template>
                        <Row
                            v-for="(reminder, j) in reminders.items"
                            :variant="'minimal'"
                            :hasError="reminder.isDue"
                            :hasBadge="reminder.isDue"
                            :items="reminder"
                            hasClick
                            @onClick="e => handleReminderClick(reminder, e)"
                            :key="j"
                            :index="j"
                        />
                    </RowWrap>
                </div>

                <p v-if="!groupedReminders.length">Noch keine Erinnerungen vorhanden.</p>
            </Card>
            <Card class="col">
                <RowWrap width="auto">
                    <template #header>
                        <Row
                            v-if="sortedCars.length > 0"
                            variant="minimal"
                            class="Row-Header"
                            :parentHover="false"
                            :items="{ row: carHeader }"
                            :key="-1"
                            :index="-1"
                            @onClickColumn="handleCarHeaderClick"
                        />
                    </template>
                    <Row
                        v-for="(car, i) in sortedCars"
                        variant="minimal"
                        :items="car"
                        :key="i"
                        :index="i"
                        :hasBadge="car.isSelected"
                        :hasClick="true"
                        :isActive="car.isSelected"
                        @onClick="handleCarClick"
                        @onHover="args => $emit('onRowHover', args)"
                        @onLeave="args => $emit('onRowLeave', args)"
                    />
                </RowWrap>
                <p v-if="!sortedCars.length">Noch keine Daten vorhanden.</p>
            </Card>
            <Card class="col col--individual-performance">
                <h3>Km-Schnitt: {{ selectedDriver.name || selectedCar.licenseNumber }}</h3>
                <div class="performance-wrap">
                    <Progress :percent="selectedItemPerformance.percentage" />

                    <div class="performance-values">
                        <div>
                            <span> Gesamt-Km: </span>
                            {{ selectedItem ? distanceToString(selectedItem.totalDistance) : 0 }}
                        </div>
                        <div>
                            <span> Besetzt-Km: </span>
                            {{ selectedItem ? distanceToString(selectedItem.hiredDistance) : 0 }}
                        </div>
                        <div>
                            <span> Besetzt-%:</span>
                            {{
                                selectedItem
                                    ? percentageToString(
                                          selectedItem.hiredDistance /
                                              (selectedItem.totalDistance || 1),
                                      )
                                    : 0
                            }}
                        </div>
                        <div>
                            <span> Schichtzeit:</span>
                            {{ selectedItem ? timeToString(selectedItem.shiftTime) : 0 }}
                        </div>
                        <div>
                            <span> Einnahmen:</span>
                            {{ selectedItem ? priceToEuroString(selectedItem.income) : 0 }}
                        </div>
                        <div>
                            <span> Euro/Km:</span>
                            {{
                                selectedItem
                                    ? '≈' +
                                      (
                                          (selectedItem.income * 10) /
                                          (selectedItem.totalDistance || 1)
                                      ).toFixed(2) +
                                      ' €'
                                    : 0
                            }}
                        </div>
                    </div>
                </div>
            </Card>

            <Card class="col">
                <h3>Durchschnittlicher Umsatz</h3>
                <Row
                    v-for="(averageIncome, i) in averageIncomeCarsAndDrivers"
                    variant="minimal"
                    :items="averageIncome"
                    :key="i"
                    :index="-1"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!averageIncomeCarsAndDrivers.length">Noch keine Daten vorhanden.</p>
            </Card>

            <Card class="col">
                <h3>Kein Signal über 2+ Tage</h3>
                <Row
                    v-for="(car, i) in carsWithNoPingInLast3Days"
                    variant="minimal"
                    :items="car"
                    :key="i"
                    :index="i"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!carsWithNoPingInLast3Days.length">Noch keine Daten vorhanden.</p>
            </Card>
            <Card class="col">
                <h3>Keine Daten über 2+ Tage</h3>
                <Row
                    v-for="(car, i) in carsWithNoDataInLast3Days"
                    variant="minimal"
                    :items="car"
                    :key="i"
                    :index="i"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!carsWithNoDataInLast3Days.length">Noch keine Daten vorhanden.</p>
            </Card>
            <Card class="col">
                <h3>Technischer Support</h3>
                <p>
                    <b>Telefon</b>:<a href="tel:+4917672905450"> 0176 7290 5450 <br /></a>
                    <b>Dienstag</b>: 14-16 Uhr <br />
                    <b>Donnerstag</b>: 16-19 Uhr <br />
                </p>
                <br />
                <hr />
                <br />
                <p>
                    <b>Email</b>: <a href="mailto:info@taxifusion.de">info@taxifusion.de</a>
                    <br />
                    <span class="Hint">Antwort innerhalb 2 Werktagen</span>
                </p>
            </Card>
        </div>
        <EmptyState v-else />
        <Modal
            title="Umsatzziel ändern"
            :show="revenueGoalModal"
            :action="{ text: 'Speichern', color: 'green', callback: handleSaveRevenueGoal }"
            :cancel="{ text: 'Abbrechen' }"
            :isLoading="isSavingRevenueGoal"
            @onModalClose="handleModalClose"
        >
            <p>
                Bitte geben Sie den neuen Umsatz ein, den Sie erreichen möchten (in Euro) pro Monat.
            </p>
            <br />
            <br />
            <label>Umsatzziel (in Euro)</label>
            <Input
                type="number"
                keyName="targetIncome"
                :value="targetIncome"
                @onKeyPress="handleTargetIncomeChange"
            />
            <br />
        </Modal>
        <NewsModal
            :show="news.length > 0"
            :title="news[0]?.title"
            :uuid="news[0]?.uuid"
            :needsConfirmation="!!news[0]?.needsConfirmation"
            :message="news[0]?.text"
            :onHasConfirmed="handleNewsModelConfirm"
            :isLoading="isNewsModalLoading"
            @onClose="handleNewsModalClose"
        />
    </div>
</template>

<script type="text/javascript">
import { fakerDE } from '@faker-js/faker';
import { fakerTR } from '@faker-js/faker';
import { fakerAR } from '@faker-js/faker';

import { mapGetters } from 'vuex';
import axios from 'axios';
import NewsModal from '@/components/NewsModal';

import {
    addMonths,
    format,
    formatISO,
    getMonth,
    isBefore,
    isFuture,
    isPast,
    startOfMonth,
    subMonths,
} from 'date-fns';
import { de } from 'date-fns/locale';
import Modal from '@/components/widgets/Modal';
import Headline from '@/components/Headline';
import Row from '@/components/Row';
import Card from '@/components/Card';
import ChartV2 from '@/components/widgets/ChartV2';
import Progress from '@/components/widgets/Progress';
import Datepicker from '@/components/widgets/Datepicker';
import Input from '@/components/widgets/Input';
import Button from '@/components/widgets/Button';

import {
    priceToEuroString,
    round2d,
    distanceToString,
    percentageToString,
    timeToString,
    euro,
} from '@/lib/helper';
import IconCog from '@/components/icons/IconCog.vue';
import EmptyState from '@/components/EmptyState.vue';
import { topicToLabel } from '../Cars/Car/components/services';
import RevenueSummary from '@/components/RevenueChartInfo.vue';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'Overview',
    components: {
        RowWrap,
        RevenueSummary,
        Button,
        Datepicker,
        Input,
        Modal,
        Headline,
        Row,
        ChartV2,
        Card,
        Progress,
        IconCog,
        EmptyState,
        NewsModal,
    },
    data: () => {
        return {
            news: [],
            monthlyIncome: 0,
            isNewsModalLoading: false,
            priceToEuroString,
            timeToString,
            distanceToString,
            percentageToString,
            yearlyIncome: 0,
            prevYearIncome: 0,
            targetIncome: 7000000,
            revenueGoal: 7000000,
            revenueGoalModal: false,
            isSavingRevenueGoal: false,
            cars: [],
            drivers: [],
            data: [],
            carData: [],
            reminders: [],
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
            selectedItem: {},
            isFetchingData: false,
            showLoader: false,
            widgets: [],
            lastMonthIncome: 0,
            driverSortState: {
                column: 'Einnahmen',
                ascending: false,
            },
            carSortState: {
                column: 'Einnahmen',
                ascending: false,
            },
        };
    },
    watch: {
        isFetchingData(newValue) {
            if (newValue) {
                // If fetching starts, show the loader immediately
                this.showLoader = true;
            } else {
                // If fetching ends, wait for fade-out animation before hiding the loader
                setTimeout(() => {
                    this.showLoader = false;
                }, 300); // Match the fade-out animation duration in CSS
            }
        },
    },
    computed: {
        ...mapGetters(['contactPerson', 'modules']),

        aggregatedMonthlyData() {
            if (!this.data.length) return [];

            // Group income by month
            const monthlyAggregation = this.data.reduce((acc, { rawDate, value }) => {
                // Extract the month and year as the key (e.g., "2024-07")
                const monthKey = format(new Date(rawDate), 'yyyy-MM');
                if (!acc[monthKey]) {
                    acc[monthKey] = 0;
                }
                acc[monthKey] += value; // Sum income for the month
                return acc;
            }, {});

            // Convert the aggregated data into the format for the chart
            const chartData = Object.entries(monthlyAggregation).map(([month, total]) => {
                const date = new Date(`${month}-01`);

                return {
                    date: format(date, 'M yy', { locale: de }), // Use full month name
                    value: total,
                    formattedValue: this.priceToEuroString(total), // Format as euro string
                    rawDate: date,
                    formatter: priceToEuroString,
                    formattedDate: format(new Date(date), 'MMM yy', { locale: de }),
                };
            });
            return chartData.length > 1 ? chartData : [];
        },
        hasNoData() {
            const nonSystemDrivers = this.drivers.filter(driver => !driver.isSystemDriver);
            return this.cars.length === 0 && nonSystemDrivers.length === 0;
        },
        hasReminderFeature() {
            return this.modules.includes('reminder');
        },
        driverHeader() {
            return [
                { name: 'Fahrer', value: null },
                { name: 'Einnahmen', value: null },
                { name: 'EURO/KM', value: null },
            ].map(header => {
                let indicator = '';
                if (this.driverSortState.column === header.name) {
                    indicator = this.driverSortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        carHeader() {
            return [
                { name: 'Fahrzeug', value: null },
                { name: 'Einnahmen', value: null },
                { name: 'EURO/KM', value: null },
            ].map(header => {
                let indicator = '';
                if (this.carSortState.column === header.name) {
                    indicator = this.carSortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        remainingIncome() {
            return Math.max(this.targetIncome * 100 - this.monthlyIncome, 0);
        },
        selectedItemPerformance() {
            const performance = Number(
                ((this.selectedItem.income * 10) / (this.selectedItem.totalDistance || 1)).toFixed(
                    2,
                ),
            );
            const percentage = Math.min((performance / 2) * 100, 100);
            if (performance > 1.39) {
                // super green
                return { performance, percentage };
            } else if (1.39 >= performance && performance > 1.19) {
                // green
                return { performance, percentage };
            } else if (1.19 >= performance && performance > 0.9) {
                // yellow
                return { performance, percentage: 40 };
            } else if (0.9 >= performance) {
                // red
                return { performance, percentage: 20 };
            }
            return { performance, percentage: 0 };
        },

        reminderHeader() {
            return [
                { name: 'Erinnerung', value: null },
                {
                    name: '',
                    value: null,
                    props: {
                        style: 'align-items: flex-end;',
                    },
                },
            ];
        },
        dueReminders() {
            const dueItems = this.groupedReminders.reduce((acc, arr = []) => {
                acc.push(...arr.items.filter(r => r.isDue));
                return acc;
            }, []);
            return dueItems;
        },
        groupedReminders() {
            const entityTranslations = {
                Driver: 'Fahrer',
                Car: 'Fahrzeug',
            };

            // Create a map of reminders grouped by entity
            const remindersByEntity = this.reminders.reduce((acc, reminder) => {
                if (!acc[reminder.entity]) {
                    acc[reminder.entity] = [];
                }
                acc[reminder.entity].push(reminder);
                return acc;
            }, {});

            const reminders = Object.keys(remindersByEntity).map(entity => {
                const reminders = remindersByEntity[entity];

                // Sort the reminders by deadline in ascending order
                reminders.sort((a, b) => new Date(a.deadline * 1000) - new Date(b.deadline * 1000));

                const items = reminders.map(reminder => {
                    const deadline = new Date(reminder.deadline * 1000);
                    const type = reminder.type;

                    const formattedDeadline =
                        type === 'dateTime'
                            ? `Am ${format(deadline, 'dd.MM.yyyy', { locale: de })}`
                            : `In ${distanceToString(
                                  Math.abs(reminder.currentValue - reminder.deadline),
                                  'km',
                              )}`;
                    const isDue =
                        type === 'dateTime'
                            ? isPast(deadline)
                            : Math.abs(reminder.currentValue - reminder.deadline) <= 0;

                    const driver = this.drivers.find(d => d.uuid === reminder.entityUuid);
                    const car = this.carData.find(c => c.uuid === reminder.entityUuid);
                    const licenseNumber = car?.licenseNumber;
                    console.log({ endDate: new Date(reminder.deadline * 1000) });
                    return {
                        index: reminder.entityUuid,
                        uuid: reminder.entityUuid,
                        isSelected: false,
                        isDue,
                        row: [
                            {
                                value:
                                    topicToLabel[reminder.topic] +
                                    ' <br>' +
                                    (driver?.name || licenseNumber || car?.vin || ''),
                                name: '',
                            },
                            {
                                value: formattedDeadline,
                                name: '',
                                props: {
                                    style: 'align-items: flex-end;',
                                },
                            },
                            {
                                name: '',
                                value: `<span style="padding:1px; font-size:1rem;cursor:pointer;"><i class="ButtonSilence ri-notification-off-line"></i></span>`,
                                props: {
                                    dayPicker: true,
                                    keyName: 'isVisible',
                                    variant: 'inline',
                                    size: 'small',
                                    style:
                                        'flex:none; height:100%; justify-content:flex-end; align-items:center; text-decoration:none; cursor:pointer;',
                                    tooltip: 'Eintrag Stummschalten',
                                    startDate: new Date(),
                                    endDate: new Date(reminder.deadline * 1000),
                                    onDateChange: range => {
                                        console.log(range, reminder);
                                        this.handleReminderMute({ reminder, range });
                                    },
                                },
                                component: 'Datepicker',
                            },
                        ],
                    };
                });

                return {
                    entity: entityTranslations[entity] || entity,
                    items,
                };
            });

            return reminders;
        },
        sortedCars() {
            return [...this.cars]
                .sort((a, b) => {
                    const aEntry = a.row.find(e => e.name === this.carSortState.column);
                    const aVal = aEntry?.rawValue;
                    const bEntry = b.row.find(e => e.name === this.carSortState.column);
                    const bVal = bEntry?.rawValue;
                    if (this.carSortState.ascending) {
                        return aVal > bVal ? 1 : -1;
                    }
                    return aVal < bVal ? 1 : -1;
                })
                .filter(d => d.shiftSums.income > 0);
        },
        sortedDrivers() {
            return [...this.drivers]
                .sort((a, b) => {
                    const aEntry = a.row.find(e => e.name === this.driverSortState.column);
                    const aVal = aEntry?.rawValue;
                    const bEntry = b.row.find(e => e.name === this.driverSortState.column);
                    const bVal = bEntry?.rawValue;
                    if (this.driverSortState.ascending) {
                        return aVal > bVal ? 1 : -1;
                    }
                    return aVal < bVal ? 1 : -1;
                })
                .filter(d => d.shiftSums.income > 0);
        },

        selectedCar() {
            return this.cars.find(car => car.isSelected) || {};
        },
        selectedDriver() {
            return (
                {
                    ...this.drivers.find(driver => driver.isSelected),
                    // name: this.handleRandomName(),
                } || {}
            );
        },
        carsWithNoDataInLast3Days() {
            const now = new Date();

            const carsWithoutData = this.cars
                .filter(car => {
                    if (!car.lastDataAt) return false;
                    const lastData = new Date(car.lastDataAt);
                    const now = new Date();
                    const diff = now.getTime() - lastData.getTime();
                    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                    return diffDays > 3;
                })
                .map(car => {
                    const lastDataDate = new Date(car.lastDataAt);
                    const daysSinceLastSignal = Math.ceil(
                        (now - lastDataDate) / (1000 * 60 * 60 * 24), // Calculate days
                    );

                    return {
                        ...car,
                        daysSinceLastSignal,
                        row: [
                            {
                                name: 'Fahrzeug',
                                value: car.licenseNumber,
                            },
                            {
                                name: 'Letztes Signal',
                                value: format(new Date(car.lastDataAt), 'dd.MM.yyyy HH:mm', {
                                    locale: de,
                                }),
                            },
                        ],
                    };
                })
                .sort((a, b) => b.daysSinceLastSignal - a.daysSinceLastSignal); // Sort by most idle

            return carsWithoutData;
        },
        mostIdleCar() {
            return this.carsWithNoDataInLast3Days.length > 0
                ? this.carsWithNoDataInLast3Days[0]
                : {};
        },

        carsWithNoPingInLast3Days() {
            const carsWithoutPing = this.cars
                .filter(car => {
                    if (!car.lastPingAt) return false;
                    const lastPing = new Date(car.lastPingAt);
                    const now = new Date();
                    const diff = now.getTime() - lastPing.getTime();
                    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                    return diffDays > 3;
                })
                .map(car => {
                    return {
                        ...car,
                        row: [
                            {
                                name: 'Fahrzeug',
                                value: car.licenseNumber,
                            },
                            {
                                name: 'Letztes Signal',
                                value: format(new Date(car.lastPingAt), 'dd.MM.yyyy HH:mm', {
                                    locale: de,
                                }),
                            },
                        ],
                    };
                })
                .sort((a, b) => {
                    const aEntry = a.row.find(e => e.name === 'Letztes Signal');
                    const aVal = aEntry?.value;
                    const bEntry = b.row.find(e => e.name === 'Letztes Signal');
                    const bVal = bEntry?.value;
                    return aVal > bVal ? 1 : -1;
                });
            return carsWithoutPing;
        },
        incorrectLogin() {
            if (!this.drivers?.length) return [];
            const incorrectDrivers = this.drivers
                .filter(driver => {
                    const drivers = JSON.parse(localStorage.getItem('drivers'));
                    if (!drivers) return false;
                    const currentDriver = drivers.find(d => d.id === driver.driverNumber);
                    return (
                        driver.driverNumber == driver.name
                        // (!currentDriver?.isVisible && driver.shiftSums.income > 0)
                    );
                })
                .map(driver => {
                    return {
                        ...driver,
                        row: [
                            {
                                name: 'Fahrer',
                                value: this.handleRandomName(),
                                value: driver.name,
                            },
                        ],
                    };
                });
            return incorrectDrivers || [];
        },
        // monthlyIncome() {
        //     return this.data.reduce((acc, item) => acc + item.value, 0);
        // },
        highestIncomeCar() {
            const car = this.cars.reduce(
                (prev, current) =>
                    prev?.shiftSums?.income > current?.shiftSums?.income ? prev : current,
                {},
            );

            return car?.shiftSums?.income > 0 ? car : { ...car, licenseNumber: 'N/A' };
        },
        highestIncomeDriver() {
            const driver = this.drivers.reduce(
                (prev, current) =>
                    prev?.shiftSums?.income > current?.shiftSums?.income ? prev : current,
                {},
            );

            return driver?.shiftSums?.income > 0 ? driver : { ...driver, name: 'N/A' };
        },
        averageIncomeActiveCars() {
            return (
                this.cars.reduce((acc, car) => acc + car?.shiftSums?.income || 0, 0) /
                this.activeCars.length
            );
        },
        averageIncomeAllCars() {
            return (
                this.cars.reduce((acc, car) => acc + car?.shiftSums?.income || 0, 0) /
                this.cars.length
            );
        },
        averageIncomeDrivers() {
            const filteredDrivers = this.drivers.filter(
                driver =>
                    !driver.isSystemDriver && (driver.isVisible || driver.shiftSums.income > 0),
            );
            return (
                filteredDrivers.reduce((acc, driver) => acc + (driver?.shiftSums?.income || 0), 0) /
                (filteredDrivers.length || 1)
            );
        },
        averageIncomeDriversWithIncome() {
            const filteredDrivers = this.drivers.filter(
                driver => !driver.isSystemDriver && driver.shiftSums.income > 0,
            );
            return (
                filteredDrivers.reduce((acc, driver) => acc + (driver?.shiftSums?.income || 0), 0) /
                (filteredDrivers.length || 1)
            );
        },
        averageIncomeCarsAndDrivers() {
            const countedVisibleDrivers = this.drivers.filter(
                driver =>
                    !driver.isSystemDriver && (driver.isVisible || driver.shiftSums.income > 0),
            );

            const countedDriversWithRevenue = this.drivers.filter(
                driver => !driver.isSystemDriver && driver.shiftSums.income > 0,
            );

            return [
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Fahrer (${countedVisibleDrivers.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeDrivers),
                        },
                    ],
                },
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Fahrer mit Umsatz (${countedDriversWithRevenue.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeDriversWithIncome),
                        },
                    ],
                },
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Alle Fahrzeuge (${this.cars.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeAllCars),
                        },
                    ],
                },
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Fahrzeuge mit Umsatz (${this.activeCars.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeActiveCars),
                        },
                    ],
                },
            ];
        },
        activeCars() {
            return this.cars.filter(car => car.isActive);
        },
    },
    methods: {
        subMonths,
        handleRandomName() {
            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
                2: `${fakerAR.person.firstName()} ${fakerAR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 3)];
        },
        handleNewsModalClose() {
            this.news.shift();
        },
        async handleNewsModelConfirm() {
            this.isNewsModalLoading = true;

            try {
                await axios.post(
                    `${process.env.VUE_APP_API_BASE_URL}/cpanel/news/${this.news[0]?.uuid}`,
                    {},
                    {
                        withCredentials: true,
                    },
                );
                this.$toasted.show('Nachricht wurde bestätigt.', {
                    type: 'success',
                });
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', {
                    type: 'error',
                });
            } finally {
                this.isNewsModalLoading = false;
            }
        },
        async handleReminderClick(rowItem, e) {
            const { event } = e;
            const target = event.target;
            if (target.classList.contains('ButtonSilence')) {
                return;
            }
            const car = this.carData.find(car => car.uuid === rowItem.uuid);
            const driver = this.drivers.find(driver => driver.uuid === rowItem.uuid);
            if (car) {
                this.$router.push({
                    path: '/cars/' + car.uuid,
                });
            } else if (driver) {
                this.$router.push({
                    path: '/employees/',
                    query: { uuid: driver.uuid },
                });
            }
        },
        async handleReminderMute({ reminder, range }) {
            const date = range.from;
            try {
                const response = await axios.patch(
                    `${process.env.VUE_APP_API_BASE_URL}/cpanel/reminders/${reminder.uuid}`,
                    {
                        dateTime: date.toISOString(),
                    },
                    {
                        withCredentials: true,
                    },
                );

                this.$toasted.show(
                    `Erinnerung wurde bis zum ${format(date, 'dd.MM.yyyy')} stummgeschaltet.`,
                    {
                        type: 'success',
                    },
                );

                this.handleQuery();
            } catch (error) {}
        },
        handleCarHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.carSortState.column === columnName) {
                this.carSortState.ascending = !this.carSortState.ascending;
            } else {
                this.carSortState.column = columnName;
                this.carSortState.ascending = true;
            }
        },
        handleDriverHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.driverSortState.column === columnName) {
                this.driverSortState.ascending = !this.driverSortState.ascending;
            } else {
                this.driverSortState.column = columnName;
                this.driverSortState.ascending = true;
            }
        },
        handleDateChange(date) {
            this.startDate = new Date(date.from);
            this.endDate = new Date(date.to);
            this.handleQuery();
        },
        setActiveBar(item) {
            this.drivers = this.drivers.map(driver => {
                return {
                    ...driver,
                    isSelected:
                        driver.driverNumber === item.driverNumber ? !driver.isSelected : false,
                };
            });
            this.cars = this.cars.map(car => {
                return {
                    ...car,
                    isSelected: car.licenseNumber === item.licenseNumber ? !car.isSelected : false,
                };
            });
        },
        handleIncorrectEditClick() {
            this.$router.push({
                name: 'shifts',
                query: { incorrect: true },
            });
        },
        handleDriverClick({ item }) {
            const activeDriver = this.drivers.find(driver => driver.isSelected);
            const selectedDriver = this.drivers.find(
                driver => driver.driverNumber === item.driverNumber,
            );
            if (selectedDriver.driverNumber == activeDriver?.driverNumber) {
                this.$router.push({
                    name: 'shifts',
                    query: {
                        driverNumber: selectedDriver.driverNumber,
                        startDate: startOfMonth(this.startDate),
                    },
                });
            } else {
                this.setActiveBar(item);
                this.selectedItem = selectedDriver.shiftSums;
            }
        },
        handleCarClick({ item }) {
            const activeCar = this.cars.find(car => car.isSelected);
            const selectedCar = this.cars.find(car => car?.licenseNumber === item.licenseNumber);
            if (selectedCar.licenseNumber == activeCar?.licenseNumber) {
                this.$router.push({
                    name: 'shifts',
                    query: {
                        licenseNumber: selectedCar.licenseNumber,
                        startDate: startOfMonth(this.startDate),
                    },
                });
            } else {
                this.setActiveBar(item);
                this.selectedItem = selectedCar.shiftSums;
            }
        },
        handleDownload(type) {},
        handleOnSettingsClick() {
            this.revenueGoalModal = true;
        },
        handleModalClose() {
            this.revenueGoalModal = false;
        },
        async handleSaveRevenueGoal() {
            this.isSavingRevenueGoal = true;
            const uuid = this.widgets.find(widget => widget.type === 'targetIncome').uuid;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/widgets/${uuid}`;
                const result = await axios.patch(
                    url,
                    {
                        name: 'Erreichter Umsatz',
                        type: 'targetIncome',
                        state: {
                            targetIncome: Number(this.targetIncome),
                        },
                    },
                    {
                        withCredentials: true,
                    },
                );
                this.$toasted.show('Umsatzziel wurde erfolgreich gespeichert.', {
                    type: 'success',
                });
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isSavingRevenueGoal = false;
                this.revenueGoalModal = false;
            }
        },
        handleTargetIncomeChange({ targetIncome }) {
            this.targetIncome = targetIncome;
        },
        async handleGetMonth({ year: givenYear, month: givenMonth }) {
            try {
                const year = givenYear || format(this.startDate, 'yyyy');
                const month = givenMonth || format(this.startDate, 'MM');
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/dashboard/${year}/${month}`;
                const result = await axios.get(url, {
                    withCredentials: true,
                });
                return result;
            } catch (e) {
                throw e;
            }
        },
        async handleGetFromTo({ from, to }) {
            try {
                const rangeStartAt = formatISO(from);
                const rangeEndAt = formatISO(to);
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/dashboard`;
                const result = await axios.post(
                    url,
                    { rangeStartAt, rangeEndAt },
                    {
                        withCredentials: true,
                    },
                );
                return result;
            } catch (e) {
                throw e;
            }
        },
        async handleQuery() {
            this.isFetchingData = true;

            try {
                const diffInMonths =
                    this.endDate.getFullYear() * 12 +
                    this.endDate.getMonth() -
                    (this.startDate.getFullYear() * 12 + this.startDate.getMonth());

                let aggregatedResponse = null;
                if (diffInMonths > 1) {
                    // Fetch the current year's data
                    const resultPromise = this.handleGetFromTo({
                        from: this.startDate,
                        to: this.endDate,
                    });

                    // Fetch the previous year's data
                    const previousStartDate = subMonths(this.startDate, 12);
                    const previousEndDate = subMonths(this.endDate, 12);
                    const prevResultPromise = this.handleGetFromTo({
                        from: previousStartDate,
                        to: previousEndDate,
                    });

                    const [result, prevResult] = await Promise.all([
                        resultPromise,
                        prevResultPromise,
                    ]);
                    aggregatedResponse = result.data;
                    console.log(result.data.dailyIncomes);
                    console.log(prevResult.data.dailyIncomes);
                    this.yearlyIncome = result.data.dailyIncomes.reduce(
                        (sum, { income }) => sum + income,
                        0,
                    );
                    this.prevYearIncome = prevResult.data.dailyIncomes.reduce(
                        (sum, { income }) => sum + income,
                        0,
                    );
                    console.log(this.prevYearIncome);
                } else {
                    // Fetch current and comparison month for single-month comparison
                    const year = format(this.startDate, 'yyyy');
                    const month = format(this.startDate, 'MM');
                    const resultPromise = this.handleGetMonth({ year, month });

                    const comparisonYear = format(subMonths(this.startDate, 1), 'yyyy');
                    const comparisonMonth = format(subMonths(this.startDate, 1), 'MM');
                    const comparisonResultPromise = this.handleGetMonth({
                        year: comparisonYear,
                        month: comparisonMonth,
                    });

                    const [result, comparisonResult] = await Promise.all([
                        resultPromise,
                        comparisonResultPromise,
                    ]);

                    aggregatedResponse = result.data;
                    this.monthlyIncome = aggregatedResponse.dailyIncomes.reduce(
                        (sum, item) => sum + item.income,
                        0,
                    );

                    const filteredLastMonthIncomes = comparisonResult.data.dailyIncomes.filter(
                        item => {
                            const itemDate = new Date(item.date);
                            const isFutureDate = isFuture(addMonths(itemDate, 1));
                            return !isFutureDate;
                        },
                    );
                    this.lastMonthIncome = filteredLastMonthIncomes.reduce(
                        (sum, item) => sum + item.income,
                        0,
                    );
                }

                // Normalize the data for the frontend
                this.normalizeFrontendData(aggregatedResponse);
            } catch (error) {
                console.error({ error });
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
                this.$emit('onFinishLoading');
            }
        },
        normalizeFrontendData(aggregatedResponse) {
            // Map daily incomes to user-friendly format
            this.data = aggregatedResponse.dailyIncomes.map(item => ({
                rawDate: item.date,
                date: format(new Date(item.date), 'dd.MM', { locale: de }),
                value: item.income,
                formatter: priceToEuroString,
                formattedValue: priceToEuroString(item.income),
                formattedDate: format(new Date(item.date), 'dd MMM yyyy', { locale: de }),
            }));

            // Assign news, widgets, reminders, and cars
            this.news = aggregatedResponse.news;
            this.widgets = aggregatedResponse.widgets;
            this.targetIncome = this.widgets.find(
                w => w.type === 'targetIncome',
            ).state.targetIncome;
            this.reminders = aggregatedResponse.reminders;

            // Map cars to a user-friendly table format
            this.cars = aggregatedResponse.licenseNumbers.map(car => ({
                ...car,
                row: [
                    {
                        name: 'Fahrzeug',
                        value: car.licenseNumber,
                        rawValue: car.licenseNumber,
                    },
                    {
                        name: 'Einnahmen',
                        value: priceToEuroString(car.shiftSums.income),
                        rawValue: car.shiftSums.income,
                    },
                    {
                        name: 'EURO/KM',
                        rawValue: car.shiftSums.totalDistance
                            ? (car.shiftSums.income * 10) / car.shiftSums.totalDistance || 0
                            : 0,
                        value:
                            '≈' +
                            (car.shiftSums.totalDistance
                                ? (car.shiftSums.income * 10) / car.shiftSums.totalDistance || 0
                                : 0
                            ).toFixed(2),
                    },
                ],
            }));
            // Map drivers to a user-friendly table format
            this.drivers = aggregatedResponse.drivers.map(driver => ({
                ...driver,
                isSelected: driver.driverNumber === this.highestIncomeDriver.driverNumber,
                row: [
                    {
                        name: 'Fahrer',
                        value: driver.name,
                        rawValue: driver.name,
                    },
                    {
                        name: 'Einnahmen',
                        value: priceToEuroString(driver.shiftSums.income),
                        rawValue: driver.shiftSums.income,
                    },
                    {
                        name: 'EURO/KM',
                        value: driver.shiftSums.totalDistance
                            ? '≈' +
                              (
                                  (driver.shiftSums.income * 10) / driver.shiftSums.totalDistance ||
                                  0
                              ).toFixed(2)
                            : '0',
                        rawValue: driver.shiftSums.totalDistance
                            ? (driver.shiftSums.income * 10) / driver.shiftSums.totalDistance
                            : 0,
                    },
                ],
            }));

            // Assign selected item (highest income driver)
            this.selectedItem = this.highestIncomeDriver.shiftSums;
        },
    },
    async mounted() {
        setTimeout(async () => {
            await this.handleQuery();
        }, 100);
    },
};
</script>

<style lang="scss">
.Overview {
    @extend %page;
    @extend %contentWrapper;

    .Wrap {
        margin-top: 0px;
        display: grid;
        gap: 4px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        // align-items: flex-start;

        @media (max-width: 1300px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 1130px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 930px) {
            grid-template-columns: repeat(auto-fill, 100%);
        }
    }

    .Card {
        &:not(.is-info):not(.is-warning) {
            padding: 0px 5px 10px;
            max-height: 346px;
            height: 100%;
            min-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;

            @media (max-width: 930px) {
                max-height: 100%;
            }

            > :first-child {
                padding-top: 10px;
            }

            h3 {
                font-weight: 700;
                margin-bottom: 15px;
                margin-left: 12px;
            }
        }
    }

    .col {
        color: var(--color-text-blue);
        position: relative;

        p {
            margin-left: 15px;
        }

        h3 {
            top: -15px;
            -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
            font-size: 1rem;
            padding: 4px 30px 5px 15px;
            width: 120%;
            background-color: var(--color-white);
            z-index: 1;
        }
    }

    .col--individual-performance {
        .performance-wrap {
            margin-left: 12px;
            display: flex;
            column-gap: 10px;
            // height: 100%;
            height: 260px;
            position: relative;
        }

        .performance-values {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            // width: 100%;
            height: 260px;
            margin-left: 30px;
            line-height: 1.5;
            font-size: 0.75rem;
            font-weight: 700;

            span {
                font-weight: 400;
                // font-size: 0.9rem;
            }
        }
    }

    .col--support {
        padding: 20px;
        box-shadow: none;
        background-color: transparent;
        color: var(--color-text-black);

        a {
            text-decoration: underline;
        }
    }

    .BubbleChart-Wrap {
        display: grid;
    }

    .Loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
        backdrop-filter: blur(5px); /* Blur effect */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; /* Ensure it's above all other content */
        transition: opacity 0.3s ease, visibility 0.3s ease;
        opacity: 1; /* Fully visible */
        visibility: visible;
        pointer-events: none; /* Prevent interactions */
    }

    .LoaderHidden {
        opacity: 0; /* Fully transparent */
        visibility: hidden; /* Remove from view after fading out */
    }

    .LoaderContent {
        text-align: center;
        animation: fadeInScale 0.5s ease-out; /* Fade-in with scaling */
        pointer-events: auto; /* Allow interactivity within loader */
    }

    .LoaderSpinner {
        width: 50px;
        height: 50px;
        border: 5px solid rgba(0, 0, 0, 0.1);
        border-top-color: var(--color-green); /* Spinner color */
        border-radius: 50%;
        animation: spin 1s infinite linear; /* Smooth infinite rotation */
        margin: 0 auto 20px;
    }

    .LoaderContent p {
        font-size: 1.2rem;
        color: var(--color-gray-dark); /* Customize color */
        margin-top: 10px;
    }

    /* Keyframes for fade-in and scaling */
    @keyframes fadeInScale {
        0% {
            opacity: 0;
            transform: scale(0.8);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    /* Keyframes for spinner rotation */
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
</style>
