var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Trips",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "items": _vm.drivers,
      "selected": _vm.selectedDriver,
      "placeholder": "Fahrer"
    },
    on: {
      "onItemSelect": _vm.handleDriverSelect
    }
  }), _c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "items": _vm.cars,
      "selected": _vm.selectedCar,
      "placeholder": "Kennzeichen"
    },
    on: {
      "onItemSelect": _vm.handleCarSelect
    }
  }), _c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "endDate": _vm.selectedTo
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleFetchTrips
    }
  }, [_vm._v(" Anzeigen ")]), _c('div', {
    staticClass: "ToolbarActions--Group"
  }, [_vm.selectedCar ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Umsatz Anzeigen',
      expression: "'In Umsatz Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('revenue');
      }
    }
  }, [_c('i', {
    staticClass: "ri-money-dollar-circle-line"
  })]) : _vm._e(), _vm.selectedCar ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Betriebsnachweise Anzeigen',
      expression: "'In Betriebsnachweise Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('operationride');
      }
    }
  }, [_c('i', {
    staticClass: "ri-car-line"
  })]) : _vm._e(), _vm.selectedCar ? _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'In Schichten Anzeigen',
      expression: "'In Schichten Anzeigen'"
    }],
    attrs: {
      "variant": "icon"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleShowOnPage('shifts');
      }
    }
  }, [_c('i', {
    staticClass: "ri-repeat-line"
  })]) : _vm._e()], 1)], 1), _c('div', [_c('Download', {
    attrs: {
      "title": "Download CSV",
      "type": "csv"
    },
    on: {
      "onDownload": _vm.handleDownload
    }
  })], 1)]), _c('TripsTable', {
    attrs: {
      "trips": _vm.trips
    },
    on: {
      "onSumOfAllGaps": _vm.handleSumOfAllGaps
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }