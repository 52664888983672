var render = function () {
  var _vm$highestIncomeDriv, _vm$highestIncomeDriv2, _vm$highestIncomeDriv3, _vm$highestIncomeCar, _vm$highestIncomeCar2, _vm$highestIncomeCar3, _vm$dueReminders, _vm$incorrectLogin, _vm$incorrectLogin2, _vm$news$, _vm$news$2, _vm$news$3, _vm$news$4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Overview",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_vm.showLoader ? _c('div', {
    class: ['Loader', {
      LoaderHidden: !_vm.isFetchingData
    }]
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    staticStyle: {
      "font-size": "1rem",
      "margin-bottom": "30px"
    }
  }, [_c('Datepicker', {
    attrs: {
      "monthPicker": "",
      "startDate": _vm.startDate,
      "end-date": _vm.endDate,
      "has-quick-actions": true
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  })], 1), _vm.aggregatedMonthlyData.length ? _c('ChartV2', {
    attrs: {
      "halfHeight": "",
      "color": "green",
      "type": "bar",
      "title": " ",
      "values": _vm.aggregatedMonthlyData
    },
    on: {
      "onLeave": function onLeave($event) {
        _vm.activeRow = null;
      }
    }
  }, [_c('RevenueSummary', {
    attrs: {
      "currentRevenue": _vm.yearlyIncome,
      "comparisonRevenue": _vm.prevYearIncome,
      "currentStartDate": _vm.startDate,
      "currentEndDate": _vm.endDate,
      "comparisonStartDate": _vm.subMonths(_vm.startDate, 12),
      "comparisonEndDate": _vm.subMonths(_vm.endDate, 12)
    }
  })], 1) : _vm._e(), _vm.data.length && !_vm.aggregatedMonthlyData.length ? _c('ChartV2', {
    attrs: {
      "halfHeight": "",
      "color": "green",
      "type": "bar",
      "values": _vm.data,
      "title": " "
    },
    on: {
      "onLeave": function onLeave($event) {
        _vm.activeRow = null;
      }
    }
  }, [_c('RevenueSummary', {
    attrs: {
      "currentRevenue": _vm.monthlyIncome,
      "comparisonRevenue": _vm.lastMonthIncome,
      "currentStartDate": _vm.startDate,
      "currentEndDate": _vm.endDate,
      "comparisonStartDate": _vm.subMonths(_vm.startDate, 1),
      "comparisonEndDate": _vm.subMonths(_vm.endDate, 1)
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "OverviewCards"
  }, [_vm.widgets.find(function (widget) {
    return widget.type === 'targetIncome';
  }) ? _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('IconCog', {
    staticStyle: {
      "color": "var(--color-text-blue)",
      "position": "absolute",
      "top": "10px",
      "right": "10px",
      "z-index": "1",
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleOnSettingsClick.apply(null, arguments);
      }
    }
  }), _c('Progress', {
    attrs: {
      "size": "small",
      "variant": "vertical",
      "showValues": true,
      "target": _vm.targetIncome * 100,
      "current": _vm.monthlyIncome
    }
  }, [_c('h2', [_vm._v(" " + _vm._s(_vm.priceToEuroString(_vm.monthlyIncome)) + " ")])])], 1) : _vm._e(), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Bester Fahrer: " + _vm._s((_vm$highestIncomeDriv = _vm.highestIncomeDriver) === null || _vm$highestIncomeDriv === void 0 ? void 0 : _vm$highestIncomeDriv.name))]), _c('h3', [_vm._v(" " + _vm._s(_vm.priceToEuroString((_vm$highestIncomeDriv2 = _vm.highestIncomeDriver) === null || _vm$highestIncomeDriv2 === void 0 ? void 0 : (_vm$highestIncomeDriv3 = _vm$highestIncomeDriv2.shiftSums) === null || _vm$highestIncomeDriv3 === void 0 ? void 0 : _vm$highestIncomeDriv3.income)) + " ")])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Bestes Fahrzeug: " + _vm._s((_vm$highestIncomeCar = _vm.highestIncomeCar) === null || _vm$highestIncomeCar === void 0 ? void 0 : _vm$highestIncomeCar.licenseNumber))]), _c('h3', [_vm._v(" " + _vm._s(_vm.priceToEuroString((_vm$highestIncomeCar2 = _vm.highestIncomeCar) === null || _vm$highestIncomeCar2 === void 0 ? void 0 : (_vm$highestIncomeCar3 = _vm$highestIncomeCar2.shiftSums) === null || _vm$highestIncomeCar3 === void 0 ? void 0 : _vm$highestIncomeCar3.income)) + " ")])]), _vm.dueReminders.length ? _c('Card', {
    attrs: {
      "variant": "warning"
    }
  }, [_c('p', [_vm._v("Fällige Termine")]), _c('h3', [_vm._v(_vm._s((_vm$dueReminders = _vm.dueReminders) === null || _vm$dueReminders === void 0 ? void 0 : _vm$dueReminders.length) + " Einträge")])]) : _vm._e(), _vm.incorrectLogin.length ? _c('Card', {
    attrs: {
      "variant": "warning"
    }
  }, [_c('p', [_vm._v("Falschanmeldungen")]), _c('h3', [_vm._v(_vm._s((_vm$incorrectLogin = _vm.incorrectLogin) === null || _vm$incorrectLogin === void 0 ? void 0 : _vm$incorrectLogin.length) + " Einträge")]), ((_vm$incorrectLogin2 = _vm.incorrectLogin) === null || _vm$incorrectLogin2 === void 0 ? void 0 : _vm$incorrectLogin2.length) > 0 ? _c('Button', {
    staticStyle: {
      "align-self": "flex-end"
    },
    attrs: {
      "size": "small",
      "marginTop": 2
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleIncorrectEditClick.apply(null, arguments);
      }
    }
  }, [_vm._v("Bearbeiten ")]) : _vm._e()], 1) : _vm._e()], 1), !_vm.hasNoData ? _c('div', {
    staticClass: "Wrap"
  }, [_c('Card', {
    staticClass: "col"
  }, [_c('RowWrap', {
    attrs: {
      "width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm.sortedDrivers.length > 0 ? _c('Row', {
          key: -1,
          staticClass: "Row-Header",
          attrs: {
            "variant": "minimal",
            "parentHover": false,
            "items": {
              row: _vm.driverHeader
            },
            "index": -1
          },
          on: {
            "onClickColumn": _vm.handleDriverHeaderClick
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 3073683264)
  }, _vm._l(_vm.sortedDrivers, function (driver, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": driver,
        "hasBadge": driver.isSelected,
        "hasClick": true,
        "index": i,
        "isActive": driver.isSelected
      },
      on: {
        "onClick": _vm.handleDriverClick,
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), 1), !_vm.sortedDrivers.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 1), _vm.hasReminderFeature && _vm.groupedReminders.length ? _c('Card', {
    staticClass: "col"
  }, [_vm._l(_vm.groupedReminders, function (reminders, i) {
    return _c('div', {
      key: i,
      staticStyle: {
        "margin-bottom": "20px"
      }
    }, [_c('RowWrap', {
      attrs: {
        "width": "auto"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [reminders.items.length > 0 ? _c('Row', {
            key: -1,
            staticClass: "Row-Header",
            attrs: {
              "variant": "minimal",
              "parentHover": false,
              "items": {
                row: _vm.reminderHeader.map(function (h, i) {
                  return Object.assign({}, h, {
                    name: i === 0 ? h.name + ' ' + reminders.entity : h.name
                  });
                })
              },
              "index": -1
            },
            on: {
              "onClickColumn": function onClickColumn() {}
            }
          }) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, _vm._l(reminders.items, function (reminder, j) {
      return _c('Row', {
        key: j,
        attrs: {
          "variant": 'minimal',
          "hasError": reminder.isDue,
          "hasBadge": reminder.isDue,
          "items": reminder,
          "hasClick": "",
          "index": j
        },
        on: {
          "onClick": function onClick(e) {
            return _vm.handleReminderClick(reminder, e);
          }
        }
      });
    }), 1)], 1);
  }), !_vm.groupedReminders.length ? _c('p', [_vm._v("Noch keine Erinnerungen vorhanden.")]) : _vm._e()], 2) : _vm._e(), _c('Card', {
    staticClass: "col"
  }, [_c('RowWrap', {
    attrs: {
      "width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm.sortedCars.length > 0 ? _c('Row', {
          key: -1,
          staticClass: "Row-Header",
          attrs: {
            "variant": "minimal",
            "parentHover": false,
            "items": {
              row: _vm.carHeader
            },
            "index": -1
          },
          on: {
            "onClickColumn": _vm.handleCarHeaderClick
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 3214948142)
  }, _vm._l(_vm.sortedCars, function (car, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": car,
        "index": i,
        "hasBadge": car.isSelected,
        "hasClick": true,
        "isActive": car.isSelected
      },
      on: {
        "onClick": _vm.handleCarClick,
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), 1), !_vm.sortedCars.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 1), _c('Card', {
    staticClass: "col col--individual-performance"
  }, [_c('h3', [_vm._v("Km-Schnitt: " + _vm._s(_vm.selectedDriver.name || _vm.selectedCar.licenseNumber))]), _c('div', {
    staticClass: "performance-wrap"
  }, [_c('Progress', {
    attrs: {
      "percent": _vm.selectedItemPerformance.percentage
    }
  }), _c('div', {
    staticClass: "performance-values"
  }, [_c('div', [_c('span', [_vm._v(" Gesamt-Km: ")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.distanceToString(_vm.selectedItem.totalDistance) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Besetzt-Km: ")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.distanceToString(_vm.selectedItem.hiredDistance) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Besetzt-%:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.percentageToString(_vm.selectedItem.hiredDistance / (_vm.selectedItem.totalDistance || 1)) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Schichtzeit:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.timeToString(_vm.selectedItem.shiftTime) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Einnahmen:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.priceToEuroString(_vm.selectedItem.income) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Euro/Km:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? '≈' + (_vm.selectedItem.income * 10 / (_vm.selectedItem.totalDistance || 1)).toFixed(2) + ' €' : 0) + " ")])])], 1)]), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Durchschnittlicher Umsatz")]), _vm._l(_vm.averageIncomeCarsAndDrivers, function (averageIncome, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": averageIncome,
        "index": -1
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.averageIncomeCarsAndDrivers.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Kein Signal über 2+ Tage")]), _vm._l(_vm.carsWithNoPingInLast3Days, function (car, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": car,
        "index": i
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.carsWithNoPingInLast3Days.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Keine Daten über 2+ Tage")]), _vm._l(_vm.carsWithNoDataInLast3Days, function (car, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": car,
        "index": i
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.carsWithNoDataInLast3Days.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Technischer Support")]), _c('p', [_c('b', [_vm._v("Telefon")]), _vm._v(":"), _c('a', {
    attrs: {
      "href": "tel:+4917672905450"
    }
  }, [_vm._v(" 0176 7290 5450 "), _c('br')]), _c('b', [_vm._v("Dienstag")]), _vm._v(": 14-16 Uhr "), _c('br'), _c('b', [_vm._v("Donnerstag")]), _vm._v(": 16-19 Uhr "), _c('br')]), _c('br'), _c('hr'), _c('br'), _c('p', [_c('b', [_vm._v("Email")]), _vm._v(": "), _c('a', {
    attrs: {
      "href": "mailto:info@taxifusion.de"
    }
  }, [_vm._v("info@taxifusion.de")]), _c('br'), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Antwort innerhalb 2 Werktagen")])])])], 1) : _c('EmptyState'), _c('Modal', {
    attrs: {
      "title": "Umsatzziel ändern",
      "show": _vm.revenueGoalModal,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSaveRevenueGoal
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isSavingRevenueGoal
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('p', [_vm._v(" Bitte geben Sie den neuen Umsatz ein, den Sie erreichen möchten (in Euro) pro Monat. ")]), _c('br'), _c('br'), _c('label', [_vm._v("Umsatzziel (in Euro)")]), _c('Input', {
    attrs: {
      "type": "number",
      "keyName": "targetIncome",
      "value": _vm.targetIncome
    },
    on: {
      "onKeyPress": _vm.handleTargetIncomeChange
    }
  }), _c('br')], 1), _c('NewsModal', {
    attrs: {
      "show": _vm.news.length > 0,
      "title": (_vm$news$ = _vm.news[0]) === null || _vm$news$ === void 0 ? void 0 : _vm$news$.title,
      "uuid": (_vm$news$2 = _vm.news[0]) === null || _vm$news$2 === void 0 ? void 0 : _vm$news$2.uuid,
      "needsConfirmation": !!((_vm$news$3 = _vm.news[0]) !== null && _vm$news$3 !== void 0 && _vm$news$3.needsConfirmation),
      "message": (_vm$news$4 = _vm.news[0]) === null || _vm$news$4 === void 0 ? void 0 : _vm$news$4.text,
      "onHasConfirmed": _vm.handleNewsModelConfirm,
      "isLoading": _vm.isNewsModalLoading
    },
    on: {
      "onClose": _vm.handleNewsModalClose
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "LoaderContent"
  }, [_c('div', {
    staticClass: "LoaderSpinner"
  }), _c('p', [_vm._v("Laden der Daten...")])]);
}]

export { render, staticRenderFns }