import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Impressum from './views/Impressum.vue';
import Register from './views/Register.vue';
import Index from './views/';
import Dashboard from './views/Dashboard/';
import Error from './views/404.vue';
import Email from './views/Email.vue';
import PasswordReset from './views/PasswordReset.vue';
import Terms from './views/Terms.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';

Vue.use(Router);

function isAuthenticated() {
    return !!localStorage.getItem('authToken');
}

const router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return new Promise(resolve => {
            if (savedPosition) {
                return savedPosition;
            }
            if (to.hash) {
                return {
                    selector: to.hash,
                    behavior: 'smooth', // Smooth scrolling enabled
                };
            }
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 500);
        });
    },

    routes: [
        {
            path: '/',
            name: 'home',
            beforeEnter: (to, from, next) => {
                if (isAuthenticated()) {
                    next({ path: '/dashboard' }); // Redirect to dashboard if authenticated
                } else {
                    next(); // Proceed to index if not authenticated
                }
            },
            component: Index,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/',
            name: 'landingPage',
            component: Index,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/reset-password',
            name: '',
            component: PasswordReset,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/verify-mail',
            name: '',
            component: Email,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/impressum',
            name: 'impressum',
            component: Impressum,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/terms-of-service',
            name: 'termsOfService',
            component: Terms,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/privacy-policy',
            name: 'privacyPolicy',
            component: PrivacyPolicy,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/sign-in',
            name: 'login',
            component: Login,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('./views/Dashboard/'),
            meta: {
                transitionName: 'slide',
                requiresAuth: true,
            },
            children: [
                {
                    path: '/',
                    name: 'overview',
                    component: () => import('./views/Dashboard/Overview'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/revenue',
                    name: 'revenue',
                    component: () => import('./views/Dashboard/Revenue'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/analytics',
                    name: 'analytics',
                    component: () => import('./views/Dashboard/Analytics'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/working-time',
                    name: 'working-time',
                    component: () => import('./views/Dashboard/Workingtime'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/shifts',
                    name: 'shifts',
                    component: () => import('./views/Dashboard/Shifts/'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/trips',
                    name: 'trips',
                    component: () => import('./views/Dashboard/Trips/'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/employees',
                    name: 'employees',
                    component: () => import('./views/Dashboard/Employees'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/schedular',
                    name: 'schedular',
                    component: () => import('./views/Dashboard/Schedular'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/cars',
                    name: 'cars',
                    component: () => import('./views/Dashboard/Cars'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/cars/:id',
                    name: 'car',
                    component: () => import('./views/Dashboard/Cars/Car'),
                },
                {
                    path: '/driversLog',
                    name: 'driversLog',
                    component: () => import('./views/Dashboard/DriversLog'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/operationride',
                    name: 'operationride',
                    component: () => import('./views/Dashboard/Operationride'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/invoice',
                    name: 'invoice',
                    component: () => import('./views/Dashboard/Invoice'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import('./views/Dashboard/Settings'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/faq',
                    name: 'faq',
                    component: () => import('./views/Dashboard/Faq'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/upgrade',
                    name: 'upgrade',
                    component: () => import('./views/Dashboard/Upgrade'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/savings-calculator',
                    name: 'savings-calculator',
                    component: () => import('./views/Dashboard/SavingsCalculator'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
                {
                    path: '/savings-calculator:id',
                    name: 'employee-revenue',
                    component: () => import('./views/Dashboard/SavingsCalculator/Employee'),
                    meta: {
                        transitionName: 'slide',
                        requiresAuth: true,
                    },
                },
            ],
        },
        {
            path: '/404',
            name: '404',
            component: Error,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '*',
            redirect: '/404',
        },
    ],
});

// Global navigation guard
router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // If the route requires authentication and the user is not logged in
    if (requiresAuth && !isAuthenticated()) {
        return next({
            path: '/sign-in',
            query: { redirect: to.fullPath },
        });
    } else {
        next();
    }
});

export default router;
