var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "modal",
    staticClass: "Modal",
    class: [{
      isActive: _vm.isActive
    }]
  }, [_c('div', {
    staticClass: "Modal-Content",
    class: "is-".concat(_vm.size)
  }, [_c('h2', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "Modal-Children"
  }, [_c('p', [_vm._v(_vm._s(_vm.text))]), _vm._t("default")], 2), _vm.cancel.text || _vm.action.text ? _c('div', {
    staticClass: "Modal-Actions"
  }, [_vm.cancel.text ? _c('Button', {
    class: "is-".concat(_vm.cancel.color),
    attrs: {
      "tabindex": "0",
      "variant": "basic"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handleAction(_vm.cancel.callback);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.cancel.text) + " ")]) : _vm._e(), _vm.action.text ? _c('Button', {
    class: "is-".concat(_vm.action.color),
    attrs: {
      "tabindex": "0",
      "variant": "success",
      "isLoading": _vm.isLoading || _vm.action.isLoading
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.handlePositiveActionClick(_vm.action.callback);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.action.text) + " ")]) : _vm._e()], 1) : _vm._e()]), _c('div', {
    staticClass: "Backdrop",
    on: {
      "click": _vm.closeModalOnBackdropClick
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }