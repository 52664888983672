<template>
    <div class="Modal" ref="modal" :class="[{ isActive: isActive }]">
        <div class="Modal-Content" :class="`is-${size}`">
            <h2>{{ title }}</h2>
            <div class="Modal-Children">
                <p>{{ text }}</p>
                <slot></slot>
            </div>
            <div class="Modal-Actions" v-if="cancel.text || action.text">
                <Button
                    v-if="cancel.text"
                    tabindex="0"
                    variant="basic"
                    :class="`is-${cancel.color}`"
                    @onClick="handleAction(cancel.callback)"
                >
                    {{ cancel.text }}
                </Button>
                <Button
                    v-if="action.text"
                    tabindex="0"
                    variant="success"
                    :isLoading="isLoading || action.isLoading"
                    :class="`is-${action.color}`"
                    @onClick="handlePositiveActionClick(action.callback)"
                >
                    {{ action.text }}
                </Button>
            </div>
        </div>
        <div class="Backdrop" @click="closeModalOnBackdropClick" />
    </div>
</template>

<script>
import Button from '@/components/widgets/Button';

export default {
    components: {
        Button,
    },
    name: 'Modal',
    props: {
        closeOnBackdropClick: {
            type: Boolean,
            default: true,
        },

        show: Boolean,
        isLoading: Boolean,
        title: String,
        text: String,
        cancel: {
            type: Object,
            default: () => ({
                text: '',
                color: '',
                callback: () => {},
            }),
        },
        action: {
            type: Object,
            default: () => ({
                text: '',
                color: '',
                callback: () => {},
            }),
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data: () => ({
        actionTextDefault: 'OK',
        cancelTextDefault: 'Cancel',
        isActive: false,
    }),
    watch: {
        show: {
            handler: function(val) {
                this.isActive = val;
                if (val) {
                    setTimeout(() => {
                        const input = this.$el.querySelector('input');
                        if (input) {
                            input.focus();
                        }
                    }, 100);
                }
            },
            immediate: true,
        },
    },
    methods: {
        closeModalOnBackdropClick() {
            if (this.closeOnBackdropClick) {
                this.closeModal();
            }
        },
        async closeModal() {
            this.$emit('onModalClose');
        },
        async handlePositiveActionClick(cb) {
            try {
                cb();
            } catch (e) {}
        },
        handleAction(cb) {
            try {
                cb();
            } catch (e) {}
            if (!cb) {
                this.closeModal();
            }
        },
    },
    mounted: function() {
        document.addEventListener('keydown', e => {
            if (this.show && e.keyCode == 27) {
                this.closeModal();
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.theme-dark {
    .Backdrop {
        background-color: rgba(0, 0, 0, 1);
    }

    .Modal-Content {
        border: 1px solid var(--color-text-inactive);
    }
}

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: zIndex(modal);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s cubic-bezier(0.14, 0.56, 0.29, 0.88);
    opacity: 0;
    will-change: contents;
    visibility: hidden;
    perspective: 800px;
    perspective-origin: center center;
    padding: 80px;

    @media screen and (max-width: breakpoint(tabletPortrait)) {
        padding: 10px;
    }

    &.isActive {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-out;

        .Modal-Content {
            transform: translateY(0px);
        }
    }

    .Backdrop {
        background-color: rgba(0, 0, 0, 0.7);
        transition: all 0.25s cubic-bezier(0.14, 0.56, 0.29, 0.88);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .Modal-Content {
        z-index: 1;
        background-color: var(--color-white);
        max-width: 60vw;
        max-height: 100%;
        padding: 30px 0px;
        transition: all 0.3s cubic-bezier(0.14, 0.56, 0.29, 0.88);
        position: relative;
        will-change: contents;
        border-radius: 10px;
        transform: translateY(50px);
        display: flex;
        flex-direction: column;

        &.is-small {
            max-width: clamp(350px, 90vw, 650px);
        }

        &.is-medium {
            min-width: clamp(350px, 60vw, 650px);
        }

        &.is-large {
            min-width: clamp(350px, 80vw, 900px);
        }

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            max-width: 100%;
            width: 100%;
            min-width: unset;
            max-height: 70vh;
            padding: 10px;
            &.is-medium {
                min-width: unset;
            }
            &.is-large {
                min-width: unset;
            }
        }

        h2 {
            padding: 0 20px;
            margin-bottom: 30px;
            margin-top: 5px;
            line-height: 1.25;
            font-size: 1.5rem;
            font-weight: 700;
            max-width: 90%;

            @media screen and (max-width: breakpoint(tabletPortrait)) {
                padding: 0 10px;
            }
        }

        p {
            font-size: 1.125rem;
            line-height: 1.5;
        }
    }

    .Modal-Children {
        overflow-x: auto;
        position: relative;
        padding: 0 20px;
        padding-bottom: 5%;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            padding: 10px;
        }
    }

    .Modal-Actions {
        bottom: 0;
        right: 0;
        padding: 30px 40px 0;
        line-height: 1.5;
        gap: 10px;
        column-gap: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        width: 100%;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            padding: 10px 0px 0;
        }

        @media screen and (max-width: breakpoint(mobile)) {
            align-items: flex-end;
        }
    }
}
</style>
