<template>
    <div>
        <div class="Register">
            <BackArrow variant="white" />
            <transition name="fade">
                <div class="Wrapper CustomerData" v-if="!registered">
                    <div class="Header">
                        <h1>Registrierung</h1>
                    </div>
                    <p>
                        Bitte füllen Sie das Formular aus, um Ihr Unternehmen zu registrieren.
                        <br />
                        Wir werden uns dann mit Ihnen in Verbindung setzen.
                    </p>
                    <br />
                    <br />

                    <form @submit.prevent="submitForm" class="CustomerForm">
                        <h3 class="FormSectionHeading">Kontaktdaten</h3>

                        <div class="FormSection">
                            <div class="InputWrap" :class="{ 'has-error': errors.contactPerson }">
                                <label for="contactPerson">Ansprechpartner</label>
                                <input
                                    class="Input"
                                    type="text"
                                    id="contactPerson"
                                    v-model="form.contactPerson"
                                />
                                <span class="Hint">Der Name des Ansprechpartners.</span>
                                <span v-if="errors.contactPerson" class="Error">{{
                                    errors.contactPerson
                                }}</span>
                            </div>

                            <div class="FormRow">
                                <div class="InputWrap" :class="{ 'has-error': errors.email }">
                                    <label for="email">Email</label>
                                    <input
                                        class="Input"
                                        type="email"
                                        id="email"
                                        v-model="form.email"
                                    />
                                    <span class="Hint">Damit wir Sie kontaktieren können.</span>
                                    <span v-if="errors.email" class="Error">{{
                                        errors.email
                                    }}</span>
                                </div>
                                <div class="InputWrap" :class="{ 'has-error': errors.phoneNumber }">
                                    <label for="phoneNumber">Telefon</label>
                                    <input
                                        class="Input"
                                        type="tel"
                                        id="phoneNumber"
                                        v-model="form.phoneNumber"
                                    />
                                    <span class="Hint">Damit wir Sie kontaktieren können.</span>
                                    <span v-if="errors.phoneNumber" class="Error">{{
                                        errors.phoneNumber
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <h3 class="FormSectionHeading">Firmendaten</h3>
                        <div class="FormSection">
                            <div class="FormRow">
                                <div class="InputWrap" :class="{ 'has-error': errors.owner }">
                                    <label for="owner">Inhaber</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="owner"
                                        v-model="form.owner"
                                    />
                                    <span class="Hint">Der Name des Inhabers.</span>
                                    <span v-if="errors.owner" class="Error">{{
                                        errors.owner
                                    }}</span>
                                </div>
                                <div
                                    class="InputWrap"
                                    :class="{ 'has-error': errors.ownerBirthdate }"
                                >
                                    <label for="ownerBirthdate">Geburtsdatum</label>
                                    <input
                                        class="Input"
                                        type="date"
                                        id="ownerBirthdate"
                                        v-model="form.ownerBirthdate"
                                    />
                                    <span class="Hint">Das Geburtsdatum des Inhabers.</span>
                                    <span v-if="errors.ownerBirthdate" class="Error">{{
                                        errors.ownerBirthdate
                                    }}</span>
                                </div>
                            </div>
                            <div class="FormRow">
                                <div
                                    class="InputWrap"
                                    :class="{ 'has-error': errors.businessName }"
                                >
                                    <label for="businessName">Firmenname</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="businessName"
                                        v-model="form.businessName"
                                    />
                                    <span class="Hint"
                                        >Der offizielle Name Ihres Unternehmens.</span
                                    >
                                    <span v-if="errors.businessName" class="Error">{{
                                        errors.businessName
                                    }}</span>
                                </div>

                                <div class="InputWrap" :class="{ 'has-error': errors.legalForm }">
                                    <label for="legalForm">Rechtsform</label>
                                    <select class="Input" id="legalForm" v-model="form.legalForm">
                                        <option value="Einzelunternehmer">Einzelunternehmen</option>
                                        <option value="GmbH">GmbH</option>
                                        <option value="UG">UG</option>
                                        <option value="AG">AG</option>
                                        <option value="OHG">OHG</option>
                                        <option value="GbR">GbR</option>
                                    </select>
                                    <span class="Hint">Die Rechtsform Ihres Unternehmens.</span>
                                    <span v-if="errors.legalForm" class="Error">{{
                                        errors.legalForm
                                    }}</span>
                                </div>
                            </div>
                            <div class="FormRow">
                                <div
                                    class="InputWrap"
                                    v-if="showTaxNumberField"
                                    :class="{ 'has-error': errors.taxNumber }"
                                >
                                    <label for="taxNumber">Steuernummer</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="taxNumber"
                                        v-model="form.taxNumber"
                                    />
                                    <span class="Hint HintWithAction"
                                        >Ihre Steuernummer.
                                        <span class="action" @click="showTaxNumberField = false">
                                            Ich habe eine USt-IdNr.</span
                                        >
                                    </span>
                                    <span v-if="errors.taxNumber" class="Error">{{
                                        errors.taxNumber
                                    }}</span>
                                </div>
                                <div
                                    class="InputWrap"
                                    v-if="!showTaxNumberField"
                                    :class="{ 'has-error': errors.taxId }"
                                >
                                    <label for="taxId">USt-IdNr.</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="taxId"
                                        v-model="form.taxId"
                                    />
                                    <span class="Hint HintWithAction"
                                        >Ihre Umsatzsteuer-Identifikationsnummer.
                                        <span class="action" @click="showTaxNumberField = true">
                                            Ich habe keine USt-IdNr.</span
                                        >
                                    </span>
                                    <span v-if="errors.taxId" class="Error">{{
                                        errors.taxId
                                    }}</span>
                                </div>
                            </div>
                            <div class="FormRow">
                                <div class="InputWrap" :class="{ 'has-error': errors.streetOne }">
                                    <label for="streetOne">Straße</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="streetOne"
                                        v-model="form.streetOne"
                                    />
                                    <span class="Hint">Die Straße Ihres Firmensitzes.</span>
                                    <span v-if="errors.streetOne" class="Error">{{
                                        errors.streetOne
                                    }}</span>
                                </div>

                                <div class="InputWrap">
                                    <label for="streetTwo">Zusatz</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="streetTwo"
                                        v-model="form.streetTwo"
                                    />
                                    <span class="Hint">Zusätzliche Adressinformationen.</span>
                                </div>
                            </div>
                            <div class="FormRow">
                                <div class="InputWrap" :class="{ 'has-error': errors.zipCode }">
                                    <label for="zipCode">PLZ</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="zipCode"
                                        v-model="form.zipCode"
                                    />
                                    <span class="Hint">Die Postleitzahl Ihres Firmensitzes.</span>
                                    <span v-if="errors.zipCode" class="Error">{{
                                        errors.zipCode
                                    }}</span>
                                </div>
                                <div class="InputWrap" :class="{ 'has-error': errors.city }">
                                    <label for="city">Ort</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="city"
                                        v-model="form.city"
                                    />
                                    <span class="Hint">Der Ort Ihres Firmensitzes.</span>
                                    <span v-if="errors.city" class="Error">{{ errors.city }}</span>
                                </div>
                            </div>
                        </div>
                        <h3 class="FormSectionHeading">Bankdaten</h3>
                        <div class="FormSection">
                            <div class="InputWrap" :class="{ 'has-error': errors.hasDirectDebit }">
                                <label for="hasDirectDebit"> Lastschrift</label>
                                <select
                                    class="Input"
                                    id="hasDirectDebit"
                                    v-model="form.hasDirectDebit"
                                >
                                    <option :value="true" selected>Ja</option>
                                    <option :value="false">Nein</option>
                                </select>
                                <span class="Hint">Möchten Sie per Lastschrift zahlen?</span>
                                <span v-if="errors.hasDirectDebit" class="Error">{{
                                    errors.hasDirectDebit
                                }}</span>
                            </div>
                            <div
                                class="FormRow"
                                v-if="form.hasDirectDebit"
                                :class="{ 'has-error': errors.iban }"
                            >
                                <div
                                    class="InputWrap"
                                    :class="{ 'has-error': errors.contactPerson }"
                                >
                                    <label for="bankAccountOwner">Kontoinhaber</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="bankAccountOwner"
                                        v-model="form.bankAccountOwner"
                                    />
                                    <span class="Hint">Kontoinhaber</span>
                                    <span v-if="errors.bankAccountOwner" class="Error">{{
                                        errors.bankAccountOwner
                                    }}</span>
                                </div>
                                <div class="InputWrap" :class="{ 'has-error': errors.bank }">
                                    <label for="bank">Bank</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="bank"
                                        v-model="form.bank"
                                    />
                                    <span class="Hint">Der Name Ihrer Bank.</span>
                                    <span v-if="errors.bank" class="Error">{{ errors.bank }}</span>
                                </div>
                            </div>
                            <div
                                class="FormRow"
                                v-if="form.hasDirectDebit"
                                :class="{ 'has-error': errors.iban }"
                            >
                                <div
                                    class="InputWrap"
                                    :class="{ 'has-error': errors.contactPerson }"
                                >
                                    <label for="iban">IBAN</label>
                                    <input
                                        class="Input"
                                        type="text"
                                        id="iban"
                                        v-model="form.iban"
                                    />
                                    <span class="Hint">Ihre IBAN.</span>
                                    <span v-if="errors.iban" class="Error">{{ errors.iban }}</span>
                                </div>
                                <div class="InputWrap" :class="{ 'has-error': errors.bic }">
                                    <label for="bic">BIC</label>
                                    <input class="Input" type="text" id="bic" v-model="form.bic" />
                                    <span class="Hint">Ihre BIC.</span>
                                    <span v-if="errors.bic" class="Error">{{ errors.bic }}</span>
                                </div>
                            </div>
                            <div class="InputWrap Row" v-if="form.hasDirectDebit">
                                <input
                                    type="checkbox"
                                    id="sepaConfirmed"
                                    v-model="form.sepaConfirmed"
                                />
                                <label for="sepaConfirmed">
                                    Hiermit ermächtige ich Nailed-IT Solutions UG widerruflich,
                                    fällige Zahlungen von meinem Konto mittels Lastschrift
                                    einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von
                                    Nailed-IT Solutions UG auf mein Konto gezogenen Lastschriften
                                    einzulösen. <br />
                                    <strong>Hinweis:</strong> Ich kann innerhalb von acht Wochen,
                                    beginnend mit dem Belastungsdatum, die Erstattung des belasteten
                                    Betrages verlangen. Es gelten dabei die mit meinem
                                    Kreditinstitut vereinbarten Bedingungen.
                                    <span v-if="errors.sepaConfirmed" class="Error">
                                        <br />{{ errors.sepaConfirmed }}</span
                                    >
                                </label>
                            </div>
                        </div>
                        <h3 class="FormSectionHeading">Account Erstellung</h3>
                        <div class="FormSection">
                            <div class="InputWrap">
                                <label for="notes">Nachricht</label>
                                <textarea class="Input" id="notes" v-model="form.notes"></textarea>

                                <span class="Hint">Zusätzliche Nachricht.</span>
                            </div>
                            <div class="FormRow">
                                <!-- Password Fields -->
                                <div
                                    class="InputWrap"
                                    :class="{ 'has-error': passwordErrors.length > 0 }"
                                >
                                    <label for="password">Passwort</label>
                                    <input
                                        class="Input"
                                        type="password"
                                        id="password"
                                        v-model="form.password"
                                        @input="handlePasswordChange"
                                    />
                                    <span class="Hint">Wählen Sie ein sicheres Passwort.</span>
                                    <ul class="PasswordRequirements">
                                        <li
                                            v-for="(requirement, index) in passwordRequirements"
                                            :key="index"
                                        >
                                            <i
                                                :class="
                                                    requirement.isValid
                                                        ? 'ri-check-line valid'
                                                        : !!requirement.isValid
                                                        ? 'ri-close-line invalid'
                                                        : 'ri-circle-line neutral'
                                                "
                                            ></i>
                                            {{ requirement.text }}
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    class="InputWrap"
                                    :class="{ 'has-error': errors.passwordConfirmation }"
                                >
                                    <label for="passwordConfirmation">Passwort bestätigen</label>
                                    <input
                                        class="Input"
                                        type="password"
                                        id="passwordConfirmation"
                                        v-model="form.passwordConfirmation"
                                    />
                                    <span class="Hint"
                                        >Passwort zur Bestätigung noch einmal ein.</span
                                    >
                                    <span v-if="errors.passwordConfirmation" class="Error">{{
                                        errors.passwordConfirmation
                                    }}</span>
                                </div>
                            </div>
                            <br />

                            <div class="InputWrap Row">
                                <input type="checkbox" id="terms" v-model="form.terms" />
                                <label for="terms">
                                    Ich habe die
                                    <router-link to="/terms-of-service" class="action"
                                        >Allgemeinen Geschäftsbedingungen
                                    </router-link>
                                    und die
                                    <router-link to="/privacy-policy" class="action"
                                        >Datenschutzerklärung
                                    </router-link>
                                    gelesen und akzeptiere sie.
                                    <br />
                                    <span v-if="errors.terms" class="Error">{{
                                        errors.terms
                                    }}</span>
                                </label>
                            </div>
                            <div class="FormActions">
                                <Button
                                    type="submit"
                                    class="SaveButton"
                                    :isLoading="isLoadingRegistration"
                                >
                                    Registrierung Abschließen
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="FormSection" v-else ref="registerSuccess">
                    <h2>Registrierung Abeschlossen</h2>
                    <p>
                        Sie wurde erfolgreich registriert.
                        <br />
                        Bitte prüfen Sie ihre E-Mail um Ihre Zugangsdaten zu erhalten.
                    </p>
                </div>
            </transition>
        </div>
        <SiteFooter />
    </div>
</template>
<script>
import BackArrow from '@/components/BackArrow';
import SiteFooter from '@/components/SiteFooter';
import Button from '@/components/widgets/Button.vue';
import axios from 'axios';

export default {
    components: {
        BackArrow,
        SiteFooter,
        Button,
    },
    data() {
        return {
            showTaxNumberField: true,
            registered: false,
            form: {
                businessName: '',
                legalForm: 'Einzelunternehmer',
                streetOne: '',
                streetTwo: '',
                zipCode: '',
                city: '',
                contactPerson: '',
                email: '',
                phoneNumber: '',
                owner: '',
                ownerBirthdate: '',
                taxNumber: '',
                taxId: '',
                // bankAccountOwner:'',
                // bank:'',
                // iban:'',
                // bic:'',
                hasDirectDebit: true,
                notes: '',
                password: '',
                passwordConfirmation: '',
                terms: '',
                sepaConfirmed: '',
                sepaTerms: '',
            },
            passwordErrors: [], // List of errors for the password field
            passwordRequirements: [
                {
                    text: 'Muss mindestens 10 Zeichen lang sein.',
                    isValid: undefined, // Updated dynamically
                    validate: password => password.length >= 10, // Rule logic
                },
                {
                    text: 'Muss mindestens einen Großbuchstaben enthalten.',
                    isValid: undefined, // Updated dynamically
                    validate: password => /[A-Z]/.test(password), // Rule logic
                },
                {
                    text: 'Muss mindestens ein Sonderzeichen enthalten.',
                    isValid: undefined, // Updated dynamically
                    validate: password => /[^a-zA-Z0-9]/.test(password), // Rule logic
                },
            ],
            isLoadingRegistration: false,
            errors: {},
        };
    },
    methods: {
        showConfetti() {
            const confetti = require('canvas-confetti');

            var canvas = document.createElement('canvas');
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            canvas.style.position = 'fixed';
            canvas.style.zIndex = '9001';
            canvas.style.top = '0px';
            canvas.style.left = '0px';
            canvas.style.pointerEvents = 'none';

            document.body.appendChild(canvas);

            var myConfetti = confetti.create(canvas, {
                resize: true,
                useWorker: true,
            });
            myConfetti({
                particleCount: 100,
                spread: 160,
                // any other options from the global
                // confetti function
            });
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                const firstErrorElement = document.querySelector('.Error');
                if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        },
        hasErrors() {
            if (!this.form.contactPerson) {
                this.errors.contactPerson = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.owner) {
                this.errors.owner = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.ownerBirthdate) {
                this.errors.ownerBirthdate = 'Dieses Feld ist erforderlich.';
            }

            if (!this.form.businessName) {
                this.errors.businessName = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.legalForm) {
                this.errors.legalForm = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.streetOne) {
                this.errors.streetOne = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.zipCode) {
                this.errors.zipCode = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.city) {
                this.errors.city = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.email) {
                this.errors.email = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.phoneNumber) {
                this.errors.phoneNumber = 'Dieses Feld ist erforderlich.';
            }

            if (!this.form.password) {
                this.errors.password = 'Ein Passwort ist erforderlich.';
            }
            if (!this.form.passwordConfirmation) {
                this.errors.passwordConfirmation = 'Bestätigung des Passworts ist erforderlich.';
            } else if (this.form.password !== this.form.passwordConfirmation) {
                this.errors.passwordConfirmation = 'Die Passwörter stimmen nicht überein.';
            }

            if (this.form.hasDirectDebit && !this.form.sepaConfirmed) {
                this.errors.sepaConfirmed =
                    'Sie müssen die SEPA-Lastschriftbedingungen akzeptieren.';
            }

            if (!this.form.bank && this.form.hasDirectDebit) {
                this.errors.bank = 'Dieses Feld ist erforderlich.';
            }
            if (!this.form.bankAccountOwner && this.form.hasDirectDebit) {
                this.errors.bankAccountOwner = 'Dieses Feld ist erforderlich.';
            }

            if (!this.form.bic && this.form.hasDirectDebit) {
                this.errors.bic = 'Dieses Feld ist erforderlich.';
            }

            if (!this.form.iban && this.form.hasDirectDebit) {
                this.errors.iban = 'Dieses Feld ist erforderlich.';
            }

            if (this.showTaxNumberField && !this.form.taxNumber) {
                this.errors.taxNumber = 'Dieses Feld ist erforderlich.';
            } else if (!this.form.taxId && !this.showTaxNumberField) {
                this.errors.taxId = 'Dieses Feld ist erforderlich.';
            }

            if (!this.form.terms) {
                this.errors.terms = 'Dieses Feld ist erforderlich.';
            }
            if (Object.keys(this.errors).length > 0) {
                this.scrollToFirstError();
                this.$toasted.error('Bitte füllen Sie alle erfoderlichen Felder aus.');
                return true;
            }
            return false;
        },
        handlePasswordChange() {
            const password = this.form.password;

            // Update requirements validity
            this.passwordRequirements.forEach(requirement => {
                requirement.isValid = requirement.validate(password);
            });

            // Collect unmet requirements for errors
            this.passwordErrors = this.passwordRequirements
                .filter(requirement => !requirement.isValid)
                .map(requirement => requirement.text);
        },
        async submitForm() {
            this.errors = {};

            this.handlePasswordChange();

            // Check if all requirements are fulfilled
            const isPasswordValid = this.passwordRequirements.every(
                requirement => requirement.isValid,
            );

            if (!isPasswordValid) {
                this.passwordErrors = this.passwordRequirements
                    .filter(requirement => !requirement.isValid)
                    .map(requirement => requirement.text);

                console.error('Password does not meet the requirements.');
                return;
            }

            const hasErrors = this.hasErrors();
            if (hasErrors) return;

            try {
                this.isLoadingRegistration = true;
                await axios.post(`${process.env.VUE_APP_API_BASE_URL}/register`, this.form);
                setTimeout(() => {
                    this.$toasted.success('Ihr Konto wurde erfolgreich angelegt.');
                    this.isLoadingRegistration = false;
                    this.registered = true;
                    this.$nextTick(() => {
                        const registerSuccess = this.$refs.registerSuccess;
                        registerSuccess.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'nearest',
                        });
                        this.showConfetti();
                    });
                }, 400);
            } catch (error) {
                this.isLoadingRegistration = false;
                const errorArr = error.response.data.errors;
                const errors = errorArr.reduce((obj, error) => {
                    if (error.message.includes('No state was found for customer')) {
                        this.$toasted.error(
                            'Bitte überprüfen Sie, ob Ihre Adresse korrekt ist und versuchen Sie es erneut.',
                        );
                    }

                    obj[error.path] = decodeURI(error.message);

                    return obj;
                }, {});
                if (!errorArr.length) {
                    this.$toasted.error(
                        'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.',
                    );
                }
                this.errors = errors;
                this.scrollToFirstError();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.Register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 20px;
    padding-bottom: 120px;
    margin-top: 90px;

    p {
        line-height: 1.75;
        margin-bottom: 0px;
    }

    h1 {
        font-weight: bold;
        margin-bottom: 40px;
    }

    h2 {
        font-weight: bold;
        margin-bottom: 10px;
    }

    h3 {
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .FormRow {
        display: flex;
        gap: 20px;
        align-items: flex-start;

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
        }
    }

    .CustomerForm {
        border-radius: 10px;
        margin: 0px auto;
    }

    .FormSection {
        padding: 40px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
        border: 1px solid #ddd;
        display: grid;
        gap: 10px;

        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 20px;
        }
    }

    .Wrapper.CustomerData {
        max-width: 900px;
        margin-top: 10px;
        padding: 0px 0 40px;
    }

    .InputWrap {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-bottom: 10px;

        &.Row {
            flex-direction: row;
            align-items: flex-start;

            label {
                margin-top: -5px;
            }
        }

        label {
            font-size: 14px;
        }

        .Input,
        textarea,
        select {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #ddd;
            background: white;

            &:focus {
                outline: none;
                border-color: var(--color-blue-light);
            }
        }

        textarea {
            min-height: 100px;
        }

        /* Style for Password Fields */
        .Input[type='password'] {
            padding: 10px;
            border-radius: 5px;
        }

        /* Style for SEPA Checkboxes */
        input[type='checkbox'] {
            width: auto;
            margin-right: 10px;
        }
    }

    .Hint {
        font-size: 12px;
        color: #666;
        width: 100%;
        margin: 0;
        display: none;

        &.HintWithAction {
            display: block;
        }
    }

    .FormActions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .SaveButton {
        background-color: var(--color-blue-light);
        color: white;
        border: none;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: var(--color-blue-dark);
        }

        @media (min-width: 600px) {
            grid-column: span 2;
        }
    }

    .BackArrow {
        @media (max-width: breakpoint(tabletLandscape)) {
            left: 10px;
        }
    }

    .Error {
        color: red;
        font-size: 12px;
        left: 0px;
        bottom: 10px;
        position: relative;
        display: block;
    }

    .action {
        color: var(--color-blue-light);
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: var(--color-blue-dark);
        }
    }

    .PasswordRequirements {
        list-style: none;
        padding-left: 0;
        margin-top: 10px;
    }

    .PasswordRequirements li {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 0.85rem;
        color: var(--color-text);
        margin-bottom: 6px;
    }

    .PasswordRequirements .neutral {
        color: var(--color-orange); // Green when fulfilled
        font-size: 1rem;
    }

    .PasswordRequirements .valid {
        color: var(--color-green-dark); // Green when fulfilled
        font-size: 1rem;
    }

    .PasswordRequirements .invalid {
        color: var(--color-red); // Red when not fulfilled
        font-size: 1rem;
    }
}
</style>
